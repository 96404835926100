.app {
    text-align: center;
    font-family: Arial, sans-serif;
    margin-top: 20px;
}

.color-picker {
    margin: 10px;
}

select, input {
    padding: 5px;
    font-size: 16px;
    margin: 5px;
}

button {
    margin-top: 10px;
    padding: 10px 20px;
    font-size: 18px;
    background-color: #4CAF50;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #45a049;
}

.resistance-display {
    margin-top: 20px;
    font-size: 20px;
}

/* استایل برای نمایش شماتیک */
.resistor-schematic {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.resistor {
    display: flex;
    justify-content: space-between;
    width: 300px;
    height: 30px;
    border: 2px solid #000;
    border-radius: 5px;
}

.band {
    width: 25px;
    height: 100%;
    border-radius: 3px;
}
